.Visao-container{
    display: flex;
    padding: 70px;
    display: flex;

    gap: 70px;
    align-items: center;
    justify-content: center;
    background-color: white;

}
h1.h1-red-2 {
    color: #E71935 !important;
    
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
}
.textos{
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 40%;
    text-align: center;
    color: black;
    font-family: "Gabarito", sans-serif;
    font-weight: 400;
}
.textos h1{
    font-family: 'monte';
    color: black;
    max-width: fit-content;
    padding: 10px 40px;
    font-size: 28px;
    cursor: pointer;
    margin: auto;
    text-align: left;
    font-weight: 5500;

}
.textos p{

    font-size: 18px;
    font-family: "Gabarito", sans-serif;
    font-weight: 500;
    text-align: left;
    text-align: center !important;
}
.textos h3{
    
    font-family: 'Reikna';
    font-size: 40px;
    font-weight: 300;
}
.textos img{
    max-width: 60px;
    margin-right: 15px;
}
.img-fofa img{
    max-width: 500px;
}

@media screen and (max-width: 768px) {
    .Visao-container {
        flex-direction: column; /* Altere para empilhar os elementos verticalmente */
        padding: 3%; /* Reduza o preenchimento para telas menores */
    }

    .textos {
        max-width: 100%; /* Altere a largura máxima para ocupar toda a largura da tela */
    }

    .textos h1 {
        font-size: 20px; /* Ajuste o tamanho da fonte para telas menores */
    }

    .textos p {
        font-size: 16px; /* Ajuste o tamanho da fonte para telas menores */
    }

    .textos h3 {
        font-size: 20px; /* Ajuste o tamanho da fonte para telas menores */
    }
}