.gabarito {
  font-family: "Gabarito", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Acumin Pro';
  src: url('./assets/font/Times\ New\ Roman\ MT\ Condensed\ Regular.otf') format('opentype');
}
@font-face {
  font-family: 'Eubergine';
  src: url('./assets/font/EubergineFont.ttf') format('truetype');
}
@font-face {
  font-family: 'monte';
  src: url('./assets/font/montserrant.ttf') format('truetype');
}
@font-face {
  font-family: 'Reikna';
  src: url('./assets/font/Reikna.ttf') format('truetype');
}
@font-face {
  font-family: 'Royal';
  src: url('./assets/font/RoyaliteScript.ttf') format('truetype');
}
@font-face {
  font-family: 'Chapman';
  src: url('./assets/font/Chapman.otf') format('opentype');
}



.body-wrapper{
  margin-top: 80px;
  min-height: 80vh;  background-color: white;
}

.body-container{
  margin: auto;
  color: aliceblue;
}

p{
  font-family: "Gabarito", sans-serif;
  letter-spacing: 0.05rem;
  font-weight: 500;
}
