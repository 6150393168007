.home-container {
  max-height: fit-content;
  background-image:linear-gradient(to top, rgba(11, 10, 10, 0), rgba(0, 0, 0, 0) 50px),  url('../../assets/imgs/fundonovo.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  margin-top: -90px;
  margin-bottom: -14px;
}

@media (max-width: 768px) {
  .home-container {
    margin-top: -90px;
    height: 60vh;
    padding: 20px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../assets/imgs/background.webp');
  }
}

@media (max-width: 480px) {
  .home-container {
    height: 70vh;
    padding: 10px;
  }
}

@media (min-width: 600px) and (max-width: 1024px) {
  .home-container {
    height: 80vh;
    background-image: url('../../assets/imgs/tablet1.jpg');
  }
}
