.somoswrapper {
  max-height: 700px;
  padding: 30px;
  background-color: black !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  margin-top: -14px;
  margin-bottom: -14px;
  border-top: 2px solid black;
  position: relative;
}
.somoswrapper::before {
  content: '';
  position: absolute;
  top: -50px; /* Ajuste para definir quanto o desfoque se estende para cima */
  left: 0;
  right: 0;
  height: 50px; /* Altura do pseudo-elemento */
  background: inherit; /* Para pegar o fundo da div */
  backdrop-filter: blur(10px); /* Ajuste a intensidade do desfoque */
  z-index: 1;
}

/* Opcional: para garantir que o conteúdo da div esteja acima do pseudo-elemento */
.somoswrapper > * {
  position: relative;
  z-index: 2;
}
.somoscontent{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding: 30px;
  background-color: black;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.texto-somos{
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  font-size: 18px;
  max-width: 600px !important;
  font-family: "Gabarito", sans-serif;
}
.texto-somos p{
  font-family: "Gabarito", sans-serif;
  font-weight: 100;
}
.auroraimg {
  max-width: 300px !important;
}
.seja-apoiador-btn{
  margin-left: 20px !important;
  padding: 10px 25px;
  background-color: #E71935;
  color: white !important;
  border-radius: 10px !important;
}
@media (max-width: 768px) {
  .somoswrapper {
    max-height: 600px ;
  }
  .texto-somos{
  
    gap: 20px;
    text-align: center;
    font-size: 14px;
    max-width: 700px !important;

  }
 
}

@media (max-width: 480px) {
}

@media (min-width: 600px) and (max-width: 1024px) {
}
