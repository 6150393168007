/* Footer.css */

.footer-background {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 40px;
  border-radius: 20px;
}
.h1-red-2{
  color: #E71935;
  font-family: "Gabarito", sans-serif;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}
.footer-container {
  background-color: black !important;
  display: flex;
  color: white;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  border-radius: 20px;

}

.footer-container-division {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  margin-bottom: 20px;
}

.footer-wrap {
  flex: 1;
  margin: 0 10px;
  text-align: center;
}

.footer-logo .footer-img {
  max-width: 150px;
}

.footer-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer-menu h5 {
  margin-bottom: 10px;
}

.footer-menu p {
  margin: 5px 0;
}

.footer-bottom-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;

  padding-top: 20px;
}

.footer-copy {
display: flex;
 gap: 20px;
 align-items: center;
 justify-content: flex-start;
  font-size: 14px;
  margin-bottom: 10px;
}




.luana-red{
  color: white !important;
}

.luana-red:hover{
  color: #FF89B0;
}
/* Media Queries */
@media (max-width: 768px) {
  .footer-container-division {
    flex-direction: column;
    align-items: center;
  }
  .footer-menu h5 {
    font-size: 16px;
  }
  .footer-wrap {
    margin: 10px 0;
  } 
  .footer-container {
    max-width: 600px;
  }
  .h1-red-2{
    color: #E71935;
    font-family: "Gabarito", sans-serif;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .footer-menu p {
    font-size: 12px !important;
  }
  .footer-logo .footer-img {
   max-width: 100px; 
}

.footer-copy a{
  font-size: 10px;
}
.footer-copy p{
  display: flex;
   gap: 10px;
    font-size: 10px;
    margin-bottom: 8px;
  }
  
}

@media (max-width: 420px) {
  .footer-menu h5 {
    font-size: 16px;
  }

  .footer-menu p {
    font-size: 14px;
  }

  .footer-copy {
    font-size: 12px;
  }

  .footer-socials a {
    font-size: 16px;
  }

  .uil,
  .fa {
    font-size: 20px;
  }
 
}
