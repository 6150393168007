.Apoiadores-container{
    padding: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px !important;

}

.apoiadores img{
    max-width: 1300px;
}

@media (max-width: 800px) {


}