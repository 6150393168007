.Acoes-container {
  background-color: white;
  display: flex;
  flex-direction: row-reverse;
  max-width: 100%;
  font-family: 'monte';
  margin-bottom: 50px;
}

.acoes-img{
  margin-top: 60px !important;
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;

}

.asterisco{
  max-width: 130px !important;
}
.img-img{
  max-width: 300px !important;
}
.Acoes-container > div{
  flex: 1;
}

.Acoes-textos {
  max-width: 800px;
  margin: 0 auto;
  color: black;
  font-family: 'monte';
}

.espacamento{
  display: flex;
  flex-direction: column;
  font-family: "Gabarito", sans-serif;
  gap: 20px;
}

p.inicial {
  font-family: "Gabarito", sans-serif;
}


@keyframes underlineAnimation {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

img.grafismo {
  max-width: 60px !important;
}
.h1-red{
  color: #E71935;
  font-family: "Gabarito", sans-serif;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20px;
}
.espacamento {
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 50px;
  font-family: "Gabarito", sans-serif;
  padding: 30px;
  font-size: 20px;
}
.seja-apoiador-btn-2{
  margin-top: 30px !important;
  padding: 10px 25px;
  background-color: #E71935;
  color: white !important;
  border-radius: 10px !important;
  margin: auto !important;
}
@media (max-width: 768px) {
  .Acoes-container {
    flex-direction: column-reverse;
  }

  .acoes-img{
    flex-direction: column;
  }

  .h1-red{
  
    font-size: 25px;
   
  }

  p.inicial {
    font-size: 16px;
  }

  .img-img{
    max-width: 250px !important;
  }
  




}
