 @keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}

.Texto2-container {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  background-color: #E71935;
}

.Texto2-content {
  background-color: #E71935;
  padding: 8px;
  display: flex;
  gap: 5px;
  width: 200%;
  animation: scroll 35s linear infinite;
}

.Texto2-content span {
  margin-left: 5px;
  font-size: 15px;
  padding: 4px;
  letter-spacing: 0.05rem;
  display: inline-block;
  width: 50%;
  color: white !important;
  font-weight: 600;
  align-items: center;
  font-family: "Gabarito", sans-serif;
}

.img-mkt2 {
  margin-right: 20px;
}

.Texto2-content img {
  max-width: 15px;
  vertical-align: middle;
  margin-left: 5px;
}

@media (max-width: 768px) {
  .img-mkt2 {
    margin-right: 5px;
  }
  
  .Texto2-content {
    padding: 2px;
    gap: 2px;
  }
  
  .Texto2-content span {
    margin-left: 5px;
    font-size: 12px;
    letter-spacing: 0.05rem;
    display: inline-block;
    width: 50%;
    color: black;
    align-items: center;
  }
}


