/* Header */
.main-header.scrolled {
  position: fixed;
  background-color: black !important;
  box-shadow: none;
  border-bottom: none;
  padding: 10px 0;
  transition: 0.4s;
}

.header-wrapper {

  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 101;
  width: 100%;
  padding: 20px 0;
  transition: 0.3s;
}
.nav-menu nav{
  display: flex !important;

}
.header-container {
  padding: 0 55px !important;
  color: rgba(255, 255, 255, 0.93);
  display: flex;
  align-items: center;
  z-index: 100;
  margin: auto;
  height: 100%;
  gap: 20px;
  justify-content: space-between;
  max-width: 1250px;
}

.header-container .logo-img{
  transition: .3s;
}

.header-container .logo-img:hover{
  transform: scale(1.05);
}


.header-container nav a {
  font-family: "Gabarito", sans-serif;
  font-size: 18px;
  color: #fff !important;
  text-transform: uppercase;
  position: relative;
  text-decoration: none;
  transition: color 0.5s ease, border-bottom-color 0.3s ease;
}

.header-container nav a:hover {
  background-color: rgba(255, 0, 0, 0.329);
}
.btn-menu{
  max-width: 40px !important;
}
.header-container nav {
  display: flex;
  gap: 25px;
  align-items: center;
  justify-content: center;
  color: #000;
}

.contato-header {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.contato-header p {
  color: #000 !important;
  font-family: "Gabarito", sans-serif;
}

.header-img {
  width: auto;
  height: 70px;

  cursor: pointer;
}

.header-flex {
  display: flex;
  align-items: center;
  font-family: "Gabarito", sans-serif;
  text-transform: uppercase;
}

.number-copy {
  font-size: 10px;
}

.header-flex svg {
  height: 32px;
  margin-right: 20px;
  color: #fff;
  stroke: #fff;
}

.header-socials {
  display: flex;
  gap: 10px;
  align-items: center;
}

.header-socials i {
  margin-left: 0;
  font-size: 14px;
}

.menu-btn {
  font-size: 40px;
  background-color: transparent;
  color: rgba(255, 255, 255, 0.93);
  display: none;
}

.menu-toggle {
  background-color: transparent;
}

.dropdown-wrapper {
  position: relative;
}

.dropdown-content {
  position: absolute;
  left: -30px;
  padding-top: 20px;
}

.dropdown-content a {
  display: block;
  background-color: black;
  padding: 30px;
  white-space: nowrap;
}

.cell-logo {
  display: none;
}

.arrow::after {
  display: inline-flex;
  content: "\f107";
  font-family: "Gabarito", sans-serif;
  margin-left: 10px;
  font-size: 11px;
}
.nav-menu {
  flex-grow: 1;
}

.nav-list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: center;
  gap: 20px;
}

.nav-item a {
  text-decoration: none;
  color: #000; /* ajuste a cor conforme necessário */
  font-weight: bold;
  padding: 10px 15px;
}


@media (min-width: 1020px)  {
  .menu-toggle {
    display: none;
  }
  div.home-container {
    margin-top: -90px;
  }
}

@media (min-width: 768px) and (max-width: 1019px) {
  /* Styles for screens between 768px and 1019px */
  .header-container {
    padding: 10px 20px;
  }
  .header-container nav {
    display: none;
  }
  .header-img {
    height: 60px;
  }
  .header-img-div {
    height: 60px;
  }
}

@media (min-width: 381px) and (max-width: 767px) {
  /* Styles for screens between 381px and 767px */
  .header-container {
    padding: 0 20px !important;
  }
  .desktop-logo {
    display: none;
  }
  .cell-logo {
    display: block;
  }
}

@media (max-width: 380px) {
  /* Styles for screens smaller than 380px */
  .header-container {
    padding: 10px;
  }
  .header-img-div {
    height: 40px;
  }
  .header-img {
    height: 40px;
  }
  .header-background {
    height: 60px;
  }
}

@media (max-width: 1095px) {
  /* Styles for screens smaller than 1095px */
  .header-socials,
  .header-flex {
    display: none;
  }
}

@media (max-width: 1020px) {
  /* Styles for screens smaller than 1020px */
  .header-container nav {
    display: none;
  }
  .menu-btn {
    display: block;
  }
}

@media (max-width: 630px) {
  /* Styles for screens smaller than 630px */
  .header-container {
    padding: 0 20px !important;

  }
  .desktop-logo {
    display: none;
  }
  .cell-logo {
    display: block;
    max-width: 150px;
  }
}

.header-wrapper.scrolled{
top: 0;
}




/*  */

.seja-apoiador-btn{
  margin-left: 20px !important;
  padding: 10px 20px;
  background-color: #E71935;
  color: white !important;
  border-radius: 10px !important;
}

