.SejaApoiador-wrapper {
  padding: 100px 20px;
  background-image: url(../../assets/imgs/fundorosared.jpg);
  background-repeat: no-repeat;
  background-position: center;
}

.SejaApoiador-container {
  position: relative;
  background-color: black;
  margin-top: -2px;
  color: white;
  max-width: 800px;
  height: 800px;
  margin: auto;
  border-radius: 20px;
}
.div-blackk{
  position: relative;
}
.form-grande {
  display: flex;
  padding: 5px;
  justify-content: center;
  align-items: center;

}

.form-grande input {
  padding: 15px 10px;
  width: 400px;
  margin: 0 auto;
  background-color: white;
}

.SejaApoiador-container h2 {
  text-align: center;
  font-size: 35px !important;
  font-weight: 400;
  letter-spacing: 0.05rem;
  color: #f8f3e0;
}

.titulisapoiadores {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

form.SejaApoiador-form {
  background-color: black;
  padding: 20px;
}

.SejaApoiador-form .form-group {
  margin-bottom: 20px;
}

.maior-input {
  height: 100px;
}

.SejaApoiador-form input {
  width: calc(100% - 22px);
  padding: 10px;
  background-color: white;
  border: none;
  color: black;
  border-bottom: 1px solid #ccc;
  border-radius: 10px;
}

.SejaApoiador-container select {
  background-color: white;
  color: black;
  border: none;
  border-bottom: 1px solid #ccc;
  margin-right: 22px;
  border-radius: 10px;
}

.SejaApoiador-container select option {
  background-color: #fff;
  color: black;
}

.SejaApoiador-container select::selection {
  background-color: #fff;
  color: black;
}

.SejaApoiador-container input:focus,
.SejaApoiador-container select:focus {
  outline: none;
}

.SejaApoiador-container .flex-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.flex-container > input,
.flex-container > select {
  flex: 1;
  display: block;
}

.seja-apoiador-btn-3 {
  margin-left: 350px;
  margin-top: 30px !important;
  padding: 10px 25px;
  background-color: #E71935;
  color: white !important;
  border-radius: 10px !important;
}

.SejaApoiador-form button:hover {
  background-color: #d57896;
}

.asteriscored {
  max-width: 70px !important;
}

.div-blackk .asteriscored {
  position: absolute;
  left: 280px;
  top: -20px;
  z-index: 1;

}
@media (max-width: 1200px) {
  .div-blackk .asteriscored {

    left: 150px;
    top: -20px;
    z-index: 1;
  }
}

@media (max-width: 800px) {
  .SejaApoiador-wrapper {
    padding: 100px 20px;
    background-image: url(../../assets/imgs/teste300.png);
    background-repeat: no-repeat;
    background-position: center;
  }
  .SejaApoiador-container {
    max-height: 560px;

  }
  .div-blackk .asteriscored {
  
    left:-15px;
    top: -20px;
    z-index: 1;
  }

  .seja-apoiador-btn-3 {
    margin-left: 120px;
    
  }
}