* {
  margin: 0;
  padding: 0;

  box-sizing: border-box;
}

li,
ul {
  list-style: none;
}

a {
  text-decoration: none;
}

i,
button,
a {
  cursor: pointer;
}

button {
  border: none;
  outline: none;
}

img {
  width: 100%;
  height: auto;
}

label {
  padding: 0;
  padding-top: 5px;
  padding-bottom: 2px;
  line-height: 1;
  font-size: 16px;
}

input {
  padding: 6px 12px;
  font-size: 16px;
}

select {
  padding: 6px 12px;
  font-size: 16px;
}

/* FIM DO RESET */

