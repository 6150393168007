
.contato-wrapper {
    z-index: 5;
    max-height: 700px !important;
    position: relative;
    background-color: white;
    padding: 100px;
  }
  .contato-flex{
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center !important;
    justify-content: center !important;
  }
  .contato-container {
    position: relative;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;;
    background-color: black !important;
    
    max-height: 500px !important;
    border-radius: 20px;
    padding: 20px;
  }

  .contato-container .asterisco{
    position: absolute;
    top:-30px;
    right: -50px;
   
  }
  .contato-titulos{
    padding:10px;
    font-family: "Gabarito", sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center !important;
    justify-content: center !important;
    gap: 20px;
    max-width: 90%;
    text-align: center;
    color: white;
  }
  img.imgrain{
    max-width: 500px;
    margin-bottom: -25px;

  }
  .h1-pink{

    color: #FF8AB1;
    font-size: 24px;
    font-family: "Gabarito", sans-serif;
  }
  .h4-contato{
    color: white;
    font-family: "Gabarito", sans-serif;
    font-size: 16px;
    font-weight: 500;
  }
  .botoes-contato{
    display: flex;
    gap: 40px !important;
  }


  @media screen and (max-width: 768px) {
    .contato-wrapper{
      padding: 26px !important;
    }
    .contato-container {
      display: flex !important;
      align-items: center;
      max-width: 400px !important;
  
    }
    
}

@media screen and (max-width: 390px) {
  .contato-wrapper{
    padding: 28px !important;
  }
  .contato-container {
    display: flex !important;
    align-items: center;
    max-width: 400px !important;

  }
  
}
